import React, { useEffect, useMemo, useState } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { LoadingButton } from "@mui/lab"
import axios, { AxiosError } from "axios"
import { useLocation, useParams } from "react-router-dom"
import { Box, Divider, Typography } from "@mui/material"

import { BanksList } from "./banksList"
import { WalletsList } from "./walletsList"
import { ErrorResponse } from "./errorPage"
import { useCheckoutContext } from "../context/checkoutContext"

import CardForm from "./cardForm"
import useSubscribe from "../hooks/useSubscribe"
import useBreakPoints from "../hooks/useBreakPoints"
import useConfirmPayment from "../hooks/useConfirmPayment"

// Interface
interface TabOptions {
    tabOptions: {
        label: string
        icon: React.ReactElement
    }[]
    methods: any
    check: boolean
    setCheck: React.Dispatch<React.SetStateAction<boolean>>
}
interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

// Tabpanel component
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
export default function IconTabs(props: TabOptions) {
    const { check, methods, tabOptions, setCheck } = props
    const { code, discountPaymentMethods, setError } = useCheckoutContext()
    const { checkoutId } = useParams()
    const {pathname} = useLocation()    
    const { sm } = useBreakPoints()

    // USESTATE HOOK
    const [value, setValue] = useState<number>(0)
    const [paymentMethod, setPaymentMethod] = useState<string>("")

    const { mutate, isLoading, data, isSuccess, error } =
        useSubscribe(checkoutId)
    const { mutate: oneTimePayment, isLoading: loading, data: paymentData, error: paymentError} = useConfirmPayment(checkoutId)

    // Event Handlers & Helpers
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        setPaymentMethod("")
    }
    const handleSubscribe = () => {
        code && code !== ""
            ? mutate({
                  payment_method: paymentMethod,
                  discount_code: code,
              })
            : mutate({
                  payment_method: paymentMethod,
              })
    }
    const handleOneTimePayment = () => {
        code && code !== ""
            ? oneTimePayment({
                  payment_method: paymentMethod,
                  discount_code: code,
              })
            : oneTimePayment({
                  payment_method: paymentMethod,
              })
    }

    // Data Structure
    const match = methods?.data.filter((item: any) =>
        discountPaymentMethods?.includes(item.id)
    )

    const banksList = useMemo(() => {
        return discountPaymentMethods?.length !== 0
            ? match.filter((item: any) => item.label === "bank")
            : methods?.data.filter((item: any) => item.label === "bank")
    }, [discountPaymentMethods])
    const walletsList = useMemo(() => {
        return discountPaymentMethods?.length !== 0
            ? match.filter((item: any) => item.label === "wallet")
            : methods?.data.filter((item: any) => item.label === "wallet")
    }, [discountPaymentMethods])

    // USEEFECTS
    useEffect(() => {
        if (isSuccess) {
            setPaymentMethod("")
        }
    }, [isSuccess])
    useEffect(() => {
        if (
            data?.data?.data?.ResponseDesc ===
            "Accept the service request successfully."
        ) {
            setCheck(true)
        }
    }, [data?.data?.data?.ResponseDesc])
    useEffect(() => {
        if (data?.data?.data) {
            window.location.href = data?.data?.data?.url ?? data?.data?.data
        }
    }, [data?.data?.data?.url])

    useEffect(() => {
        function useAnchorOpen(link: string) {
            const anchorEle = document.createElement("a");
            anchorEle.setAttribute("href", link);
            anchorEle.setAttribute("target", "_blank");
            anchorEle.setAttribute("rel", "external");
            anchorEle.style.display = "none";
            anchorEle.click();
          }
          
        if (paymentData?.data?.data?.url) {
            useAnchorOpen(paymentData.data.data.url.trim());
            setCheck(true)
        }
    }, [paymentData?.data?.data])

    if (error || paymentError) {
        const err = error || paymentError as AxiosError
        if (axios.isAxiosError(err)) {
            const newErr = err?.response?.data as ErrorResponse
            setError(newErr)
        }
    }
    // Render
    return (
        <React.Fragment>
            <Box sx={{ width: "100%" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="icon tabs"
                    variant={sm ? undefined : "scrollable"}
                    scrollButtons={sm ? undefined : "auto"}
                    sx={{
                        "& .MuiTab-root": {
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            minHeight: "50px",
                            padding: "4px 16px",
                            marginRight: sm ? "27px" : "8px",
                            my: "0.5em",
                        },
                        "& .MuiTabs-indicator": {
                            display: "none",
                        },
                    }}
                >
                    {tabOptions.map(
                        (
                            item: { label: string; icon: React.ReactElement },
                            index: number
                        ) => (
                            <Tab
                                key={index}
                                label={item.label}
                                iconPosition="end"
                                icon={React.cloneElement(item.icon, {
                                    color:
                                        value === index ? "white" : "inherit",
                                })}
                                aria-label={item.label}
                                sx={{
                                    width: `${
                                        (sm ? 84 : 100) / tabOptions.length
                                    }%`,
                                    minWidth: "0", // Allow tabs to shrink when necessary
                                    borderRadius: "8px",
                                    bgcolor:
                                        value === index ? "primary.main" : "#f7f7f7",
                                    textTransform: "none",
                                    fontWeight: value === index ? 900 : 500,
                                    color: "black",
                                    boxShadow: value === index ? 3 : 0,
                                    "&.Mui-selected": {
                                        color: "black",
                                    },
                                }}
                            />
                        )
                    )}
                </Tabs>
            </Box>
            <Divider sx={{ my: 4 }} />
            <TabPanel value={value} index={0}>
                <WalletsList
                    wallets={walletsList}
                    setPaymentMethod={setPaymentMethod}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BanksList
                    banks={banksList}
                    setPaymentMethod={setPaymentMethod}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CardForm />
            </TabPanel>
            <LoadingButton
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                onClick={pathname?.includes("onetime")? handleOneTimePayment: handleSubscribe}
                loading={isLoading || check || loading}
                disabled={paymentMethod === "" || check}
                sx={{
                    textTransform: "none",
                    fontWeight: "bold",
                    bgcolor: "primary.main",
                    color: "black",
                    boxShadow: 4,
                    height: 50,
                    mt: 2,
                    mb: { xs: "1em", sm: "1em", md: 0 },
                    ":hover": {
                        bgcolor: "primary.main",
                    },
                }}
            >
                {isLoading || check || loading ? "Loading" : pathname?.includes("onetime")? "Pay" :"Subscribe"}
            </LoadingButton>
        </React.Fragment>
    )
}
