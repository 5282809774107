import { useEffect, useMemo, useRef, useState } from "react"
import axios, { AxiosError } from "axios"
import { useParams } from "react-router-dom"
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    Typography,
} from "@mui/material"
import {
    AccountBalance,
    AccountBalanceWallet,
    ArrowBack,
    CreditCard,
} from "@mui/icons-material"

import ErrorPage, { ErrorResponse } from "../components/errorPage"
import PaymentDetails from "../components/paymentDetails"
import FailurePage from "../components/failurePage"
import SuccessPage from "../components/successPage"
import IconTabs from "../components/iconTabs"
import Loading from "../components/loading"

import useGetEntity from "../hooks/useGetEntity"
import useGetEntities from "../hooks/useGetEntities"

import { useCheckoutContext } from "../context/checkoutContext"
import { BASE_URL, VERSION } from "../utils/config"

// Options for selecting payment methods
const tabOptions = [
    {
        label: "Wallet",
        icon: <AccountBalanceWallet />,
    },
    {
        label: "Bank",
        icon: <AccountBalance />,
    },
    {
        label: "Card",
        icon: <CreditCard />,
    },
]

// One-Time Payment Checkout Page Component
function OneTimePayement() {
    const { checkoutId } = useParams()
    const { error } = useCheckoutContext()

    const [check, setCheck] = useState<boolean>(false)
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>("")

    const socket = useRef<any>()

    // Queries
    const {
        data: paymentMethods,
        isLoading: paymentMethodsIsLoading,
        error: paymentMethodsDetailsError,
    } = useGetEntities({
        endPoint: "payment-methods",
        perPage: -1,
        filterModel: [
            {
                column_field: "web_support",
                operator_value: "contains",
                value: "YES",
            },
        ],
    })
    const {
        data: paymentIntentDetails,
        isLoading: paymentIntentDetailsIsLoading,
        error: paymentIntentDetailsError,
    } = useGetEntity(
        "payment_intent_details",
        "payment-intents",
        `${checkoutId}`
    )

    const requestError = paymentIntentDetailsError || paymentMethodsDetailsError

    const paymentDetails = useMemo(
        () => ({
            merchant: paymentIntentDetails?.data?.merchant,
            price: {
                amount: paymentIntentDetails?.data?.amount,
                name: `Pay to ${paymentIntentDetails?.data?.merchant?.name}`,
            },
            currency: { code: paymentIntentDetails?.data?.currency?.code },
        }),
        [paymentIntentDetails?.data]
    )

    // USEEFFECTS
    useEffect(() => {
        if (check) {
            socket.current = new WebSocket(
                `${BASE_URL?.replace(
                    "https",
                    "wss"
                )}/${VERSION}/payment_verification/${checkoutId}`
            )
        }
    }, [checkoutId, check])
    useEffect(() => {
        if (check) {
            socket.current.onmessage = (event: any) => {
                const data = JSON.parse(event.data)
                if (data.ok) {
                    setSubscriptionStatus(data?.data?.data?.status)
                    setCheck(false)
                    socket.current?.close()
                }
            }
        }
    }, [check])
    useEffect(() => {
        if (paymentIntentDetails?.data?.return_url) {
            localStorage.setItem(
                "return_url",
                paymentIntentDetails?.data?.return_url
            )
        }
    }, [paymentIntentDetails?.data?.return_url])

    // Loading Queries
    if (paymentMethodsIsLoading || paymentIntentDetailsIsLoading) {
        return (
            <Loading
                message="Loading"
                merchantName={
                    paymentIntentDetails?.data?.merchant?.merchant_name
                }
            />
        )
    }

    // Query Errors
    if (requestError) {
        const err = requestError as AxiosError
        if (axios.isAxiosError(err)) {
            const newErr = err?.response?.data as ErrorResponse
            return (
                <ErrorPage
                    {...newErr}
                    merchantName={
                        paymentIntentDetails?.data?.merchant?.merchant_name
                    }
                />
            )
        }
    }

    // Payment Processing
    if (check)
        return (
            <Loading
                message="Processing"
                merchantName={
                    paymentIntentDetails?.data?.merchant?.merchant_name
                }
            />
        )

    // Error from the context
    if (error)
        return (
            <ErrorPage
                {...error}
                merchantName={
                    paymentIntentDetails?.data?.merchant?.merchant_name
                }
            />
        )

    // Payment Process Errors
    if (subscriptionStatus === "FAILED") {
        return (
            <FailurePage
                message={"The payment attempt has failed."}
                merchantName={
                    paymentIntentDetails?.data?.merchant?.merchant_name
                }
            />
        )
    }
    if (subscriptionStatus === "ACTIVE" || subscriptionStatus === "SUCCEEDED") {
        return (
            <SuccessPage
                merchantName={
                    paymentIntentDetails?.data?.merchant?.merchant_name
                }
            />
        )
    }
    // Render
    return (
        <Box
            sx={{
                px: { sm: 4, xs: 1 },
                mb: 2,
                mx: { sm: 7, xs: 1 },
            }}
        >
            <Grid
                container
                sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                }}
            >
                <Grid item sx={{ justifyContent: "start" }}>
                    <Avatar
                        variant="square"
                        alt="Avatar"
                        src={process.env.PUBLIC_URL + "/assets/logo.svg"}
                        sx={{
                            top: 0,
                            left: 0,
                            width: 100,
                            height: 130,
                            bgcolor: "transparent",
                        }}
                    />
                </Grid>
                <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                        mt: -4,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <IconButton
                        onClick={() =>
                            (window.location.href =
                                localStorage.getItem("return_url") ?? "")
                        }
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {paymentIntentDetails?.data?.merchant?.merchant_name}
                    </Typography>
                </Grid>
            </Grid>
            <Divider
                orientation="horizontal"
                sx={{ my: 3, ml: { xs: 0, md: "3em" } }}
            />
            <Grid
                container
                columnSpacing={16}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 1,
                    pl: { xs: 0, md: "3em" },
                }}
            >
                <Grid
                    item
                    sm={12}
                    md={6}
                    xs={12}
                    sx={{
                        mb: { xs: 3, sm: 3, md: 0 },
                    }}
                >
                    <PaymentDetails details={paymentDetails} />
                </Grid>
                <Grid item sm={12} md={6} xs={12}>
                    <Typography
                        sx={{ fontWeight: "bold", fontSize: 16, mb: 1 }}
                    >
                        Payment Method
                    </Typography>
                    <IconTabs
                        check={check}
                        tabOptions={tabOptions}
                        methods={paymentMethods}
                        setCheck={setCheck}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
export default OneTimePayement
