// import { useEffect, useState } from "react"
import {
    Box,
    // Button,
    Card,
    CardMedia,
    // CircularProgress,
    // Divider,
    // IconButton,
    // Paper,
    // TextField,
    Typography,
} from "@mui/material"

import { CapitalizeWordAndAddLy } from "../utils/capitalizeWord"
// import useUpdateEntity from "../hooks/useUpdateEntity"
// import { useLocation, useParams } from "react-router-dom"
// import { Close } from "@mui/icons-material"
// import { useCheckoutContext } from "../context/checkoutContext"

interface PaymentDetails {
    merchant: { logo: string }
    price: { amount: string; frequency?: string; name: string }
    currency: {
        code: string
    }
}
const PaymentDetails = ({ details }: { details: PaymentDetails }) => {
    // const { checkoutId } = useParams()
    // const {pathname} = useLocation() 

    // const { setCode, setDiscountPaymentMethods } = useCheckoutContext()

    // const [view, setView] = useState<boolean>(true)
    // const [viewDiscount, setViewDiscount] = useState<boolean>(false)
    // const [inputValue, setInputValue] = useState("")
    // const [error, setError] = useState(false)

    // const { mutate, data, isLoading } = useUpdateEntity(
    //     `/discounts/validate`
    // ) as any

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setInputValue(event.target.value)
    //     setError(false)
    // }

    // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault()

    //     if (inputValue.trim() === "") {
    //         setError(true)
    //         return
    //     }
    //     mutate({
    //         discount_code: inputValue,
    //         id: checkoutId,
    //         payment_type: pathname?.includes("onetime")? "ONETIME":"RECURRING",
    //     })
    // }
    // useEffect(() => {
    //     if (data?.data?.data) {
    //         setCode(data?.data?.data?.code)
    //         setDiscountPaymentMethods(data?.data?.data?.payment_method_ids)
    //         // setViewDiscount(true)
    //     }
    // }, [data?.data?.data])
    return (
        <Box
            sx={{
                background: "#FAFAFA",
                border: "1px solid #E4E4E4",
                borderRadius: "7px",
                textAlign: "left",
                pl: "1.5rem",
                pr: { md: 0, sm: "1.5rem", xs: "1.5rem" },
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                    px: 2,
                    py: 4,
                }}
            >
                <Card sx={{ maxWidth: 90 }}>
                    <CardMedia
                        component="img"
                        alt="merchant_logo"
                        height="100"
                        image={details?.merchant?.logo}
                    />
                </Card>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    <Typography
                        sx={{ color: "gray", fontWeight: "bold" }}
                        variant="body2"
                    >
                        {details?.price?.name}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "flex-end",
                        }}
                    >
                        {details?.price?.frequency && (
                            <Typography
                                sx={{ color: "common.black" }}
                                variant="h5"
                            >
                                {CapitalizeWordAndAddLy(
                                    details?.price?.frequency
                                )}{" "}
                            </Typography>
                        )}
                        <Typography
                            sx={{ color: "common.black", ml: details?.price?.frequency? 1 : 0 }}
                            variant="h5"
                        >
                            {details?.price?.amount}
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: "center",
                                ml: 0.5,
                                mb: 0.3,
                                color: "gray",
                                fontWeight: "bold",
                                fontSize: 14,
                            }}
                        >
                            {details?.currency?.code ?? "ETB"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/* To be used when the dicount implementation is needed
            <Box
                sx={{
                    ml: { sm: "130px", xs: 0 },
                    mr: { sm: 4, xs: 0 },
                    mb: 2,
                }}
            >
                <Divider sx={{ mb: 1 }} />
                {viewDiscount ? (
                    <Paper
                        sx={{
                            justifyContent: "start",
                            alignItems: "center",
                            px: "0.8em",
                            mt: "1em",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mb: {
                                    xs: "-0.5em",
                                    sm: "-2.5em",
                                    md: "-0.5em",
                                },
                                pt: "0.5em",
                            }}
                        >
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setView(true)
                                    setViewDiscount(false)
                                }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: { xs: "start", md: "center" },
                                flexDirection: { xs: "column", md: "row" },
                                gap: { xs: 0, sm: 2 },
                                ml: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    bgcolor: "#f7f7f7",
                                    my: "1em",
                                    px: "1em",
                                    py: "0.5em",
                                    maxWidth: {
                                        xs: "100%",
                                        sm: "auto",
                                        md: "90%",
                                        lg: "70%",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{ color: "common.black" }}
                                    variant="body1"
                                >
                                    {data?.data?.data?.name ?? ""}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ color: "primary.main" }}
                                >
                                    {data?.data?.data?.discount_type === "FLAT"
                                        ? Number(
                                              data?.data?.data?.amount * 100
                                          ) /
                                          Number(data?.data?.data?.old_amount)
                                        : data?.data?.data?.amount}
                                    % Off
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "baseline",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ color: "common.black" }}
                                >
                                    -
                                    {data?.data?.data?.discount_type === "FLAT"
                                        ? data?.data?.data?.amount
                                        : Number(data?.data?.data?.old_amount) -
                                          Number(data?.data?.data?.amount)}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textAlign: "center",
                                        ml: 0.5,
                                        color: "gray",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {details?.currency?.code ?? "ETB"}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ mx: 1, my: "0.5em" }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "baseline",
                            }}
                        >
                            <Typography
                                sx={{ color: "common.black", ml: 1, mb: "1em" }}
                                variant="h5"
                            >
                                {data?.data?.data?.discounted_amount}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "center",
                                    ml: 0.5,
                                    color: "gray",
                                    fontWeight: "bold",
                                }}
                            >
                                {details?.currency?.code ?? "ETB"}
                            </Typography>
                        </Box>
                    </Paper>
                ) : view ? (
                    <Button
                        sx={{
                            textTransform: "none",
                            fontWeight: "bold",
                            color: "primary.main",
                            fontSize: 16,
                            mt: "0.5em",
                            px: 1,
                            mb: 2,
                            ml: -1,
                        }}
                        onClick={() => {
                            setView(false)
                            setViewDiscount(false)
                        }}
                    >
                        Add Discount
                    </Button>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                        }}
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Discount Code"
                            size="small"
                            value={inputValue}
                            onChange={handleChange}
                            error={error}
                            helperText={error ? "Input cannot be empty" : ""}
                            required
                            sx={{
                                mt: "0.5em",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Button
                                        sx={{
                                            mr: -2,
                                        }}
                                        component="label"
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <Button
                                                sx={{
                                                    textTransform: "none",
                                                    fontWeight: "bold",
                                                    color: "primary.main",
                                                    fontSize: 13,
                                                }}
                                                type="submit"
                                            >
                                                Apply
                                            </Button>
                                        )}
                                    </Button>
                                ),
                            }}
                        />
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setView(true)
                                setViewDiscount(false)
                            }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    </Box>
                )}
            </Box> */}
        </Box>
    )
}

export default PaymentDetails
